import 'regenerator-runtime/runtime'

(function (){
  // const formButton = document.getElementById('formButton')
  // formButton.addEventListener('click', sendForm)
  // const validate = (id, funcValidate, errorText) => {
  //   const item = document.getElementById(id);
  //   const errorItem =  document.getElementById(id).nextElementSibling;
  //   const isValid = funcValidate(item.value);
  //   if(!isValid){
  //     errorItem.innerHTML = errorText;
  //     return false;
  //   }
  //   if(isValid && errorItem.innerHTML === errorText){
  //     errorItem.innerHTML = '';
  //   }
  //   return true;
  // }
  //
  // const validateEmail = (email) => {
  //   return String(email)
  //     .toLowerCase()
  //     .match(
  //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //     );
  // };
  //
  // async function sendForm (event){
  //   event.preventDefault();
  //   const form = document.getElementById('form')
  //   const data = new FormData(form);
  //   const isMailValid = validate('mail', validateEmail, 'Некорректная почта');
  //   const agreement = document.getElementById('agreement');
  //   const checkBox = document.getElementById('checkbox');
  //   if(!agreement.checked){
  //     checkBox.classList.add('checkboxError');
  //   }
  //   if(isMailValid && agreement.checked){
  //     let response = await fetch('/user', {
  //       method: 'POST',
  //       body: data
  //     });
  //   }
  // }

  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      });
    });
  });

})()
